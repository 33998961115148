import { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import '../components/Contact.css';
import '../components/Root.css';

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    newsletter: false,
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), {
        ...formData,
        timestamp: serverTimestamp(),
      });
      setMessage("Message sent successfully. Check your email.");
    } catch (e) {
      console.error("Error adding document: ", e);
      setMessage("Failed to send message.");
    }
  };

  useEffect(() => {
    // Add the class to the body when the component is mounted
    document.body.classList.add('contact-page');

    // Remove the class from the body when the component is unmounted
    return () => {
      document.body.classList.remove('contact-page');
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="contact-section">
        <div className="contact-left">
          <h1 className="contact-title">
            Let's <span className="contact-title-green">Talk</span>
          </h1>
          <p className="contact-left-text">
          Let’s bring your ideas to life! Whether you have a project in mind, need expert insights, or just want to connect, feel free to reach out. I’m excited to hear from you!
          </p>
          <div className="contact-left-mail">
          <i class="bi bi-envelope"></i>
            <p className="contact-left-mail-text">
              pauline.milaalonso@gmail.com
            </p>
          </div>
        </div>
        <form className="contact-right" onSubmit={handleSubmit}>
          <div className="contact-right-line">
            <div className="contact-right-line-box">
              <p className="contact-right-line-box-title">
                First-name <span className="contact-right-line-box-title-green">*</span>
              </p>
              <input
                className="contact-right-line-box-input"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="contact-right-line-box">
              <p className="contact-right-line-box-title">
                Last-name <span className="contact-right-line-box-title-green">*</span>
              </p>
              <input
                className="contact-right-line-box-input"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="contact-right-line">
            <div className="contact-right-line-box">
              <p className="contact-right-line-box-title">
                Email <span className="contact-right-line-box-title-green">*</span>
              </p>
              <input
                className="contact-right-line-box-input"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="contact-right-line-box">
              <p className="contact-right-line-box-title">
                Téléphone
              </p>
              <input
                className="contact-right-line-box-input"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="contact-right-line-big-box">
              <p className="contact-right-line-box-title">
                Message <span className="contact-right-line-box-title-green">*</span>
              </p>
              <textarea
                className="contact-right-line-box-textarea"
                name="message"
                placeholder="Type your message..."
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="contact-right-line-nl">
                <input
                  type="checkbox"
                  id="newsletter-checkbox"
                  className="checkbox"
                  name="newsletter"
                  checked={formData.newsletter}
                  onChange={handleChange}
                />
                <label htmlFor="newsletter-checkbox" className="contact-right-line-nl-text">
                Sign me up for the Newsletter
                </label>
            </div>
            <button className="contact-right-submit-btn" type="submit">
              Send <i className="bi bi-arrow-right"></i>
            </button>
            {message && <p className="contact-message-send">{message}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import "../Root.css";

const baseTestimonials = [
  {
    id: 1,
    stars: 5,
    comment: "Suivi régulier très professionnel, exigences respectées et DA osée : tout ce que j'aime réuni dans une seule boite ! Merci",
    name: "Nolann",
    position: "Community Manager",
    photo: "./images/testimonials-nono.png"
  },
  {
    id: 2,
    stars: 5,
    comment: "J'ai apprécié de collaborer avec Pauline. C'est une personne agréable et compétente ! Son travail est propre avec une touche moderne. Elle reste toujours à l'écoute du client et n'a pas peur d'expérimenter. Elle planifie bien son travail et respecte le délais.",
    name: "Tatiana",
    position: "Idem Automatisme",
    photo: "./images/testimonials-idem.png"
  },
  {
    id: 3,
    stars: 5,
    comment: "Site très clair, correspondant à nos attentes",
    name: "Mathieu",
    position: "FFN Rhône-Alpes",
    photo: "./images/testimonials-naturismera.png"
  },
  {
    id: 4,
    stars: 5,
    comment: "Rapide et efficace du 1er contact jusqu'à la fin du projet",
    name: "Etienne",
    position: "Product Owner",
    photo: "./images/testimonials-etienne.png"
  }
];

// Repeat testimonials 100 times to simulate infinite scrolling
const testimonials = Array.from({ length: 100 }, (_, i) => baseTestimonials[i % baseTestimonials.length]);

export default function Testimonials() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1
    );
  };

  const getVisibleSlides = () => {
    const slides = [];
    for (let i = -1; i <= 1; i++) {
      slides.push(testimonials[(currentSlide + i + testimonials.length) % testimonials.length]);
    }
    return slides;
  };

  return (
    <div className="testimonials-section">
      <div className="testimonials-top">
        <h2 className="testimonials-title">
          Testimonials that speak to
          <span className="testimonials-title-green"> My Results</span>
        </h2>
        <p className="testimonials-description">
        Discover what my clients have to say about their experience working with me. Their feedback speaks for itself—quality, reliability, and innovation in every project.

        </p>
      </div>
      <div className="testimonials-bottom">
        <div className="testimonials-slider">
          {getVisibleSlides().map((testimonial, index) => (
            <div
              key={index}
              className={`testimonial-card ${
                index === 1 ? "active" : index === 0 ? "prev" : "next"
              }`}
            >
              <div className="testimonial-stars">
                {"★".repeat(testimonial.stars)}
                {"☆".repeat(5 - testimonial.stars)}
              </div>
              <p className="testimonial-comment">{testimonial.comment}</p>
              <div className="testimonial-profile">
                <img
                  src={testimonial.photo}
                  alt={testimonial.name}
                  className="testimonial-photo"
                />
                <div className="testimonial-info">
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-position">{testimonial.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="testimonials-navigation">
          <button className="testimonial-prev" onClick={prevSlide}><i class="bi bi-arrow-left"></i></button>
          <button className="testimonial-next" onClick={nextSlide}><i class="bi bi-arrow-right"></i></button>
        </div>
      </div>
    </div>
  );
}

import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

export default function About() {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    )
}
import Footer from "../components/Footer"
import PortfolioSection from "../components/home/PortfolioSection"
import Navbar from "../components/Navbar"

export default function Services() {
    return (
        <>
            <Navbar />
            <PortfolioSection />
            <Footer />
        </>
    )
}
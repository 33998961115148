import "./Hero.css";

export default function Hero() {
    return (
        <div className="hero-section">
        <p className="hero-label">Hello!</p>
        <h1 className="hero-title">I'm <span className="hero-title-green">Pauline,</span><br/>Designer <span className="hero-title-green">&</span> Developer</h1>
        <img src="./images/hero-img.png"  className="hero-img"></img>
        <div className="hero-btn-box">
            <a href="/home#portfolio" className="hero-cta-black">Portfolio</a>
            <a href="/contact" className="hero-cta-green">Hire Me<i class="bi bi-arrow-up-right"></i></a>
        </div>
            
        </div>
    )
}
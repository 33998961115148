import React, { useEffect, useRef, useState } from "react";
import "./MiniPortoflio.css";
import Testimonials from "./Testimonials";

const clientLogos = [
    "./images/logos/logo-cleo.png",
    "./images/logos/logo-logitech.png",
    "./images/logos/logo-ffn.png",
    "./images/logos/logo-thirdspace.png",
];

const projects = [
    { id: 1, image: "./images/portfolio-etienne.png", title: "Portfolio Etienne Dubois", description: "Luxurious portfolio combining passion for diving and product ownership.", link: "https://etiennedubois.fr/", logo: "./images/logos/etienne-logo.png" },
    { id: 13, image: "./images/portfolio-logitech-1.png", title: "Logitech - LogiPlay", description: "Landing page for the launch of Logitech’s new racing sets.", link: "", logo: "./images/logos/logo-logitech.png" },
    { id: 11, image: "./images/portfolio-cleo-1.png", title: "Cleo", description: "Showcase website for Cleo Academy, an AI-driven e-learning platform.", link: "https://www.cleo.academy/", logo: "./images/logos/logo-cleo.png" },
    { id: 2, image: "./images/portfolio-3s.png", title: "Third Space", description: "Website for a no-code web3 builder.", link: "https://3rd-space.io/", logo: "./images/logos/thirdspace-logo.png" },
    { id: 12, image: "./images/portfolio-cleo-2.png", title: "Cleo App", description: "AI-driven e-learning platform", link: "", logo: "./images/logos/logo-cleo.png" },
    { id: 3, image: "./images/portfolio-victor.png", title: "Portfolio Victor Bisch", description: "Minecraft-themed portfolio adapted for mobile format.", link: "https://portfoliovictor.com/eng/index.html", logo: "./images/logos/victor-logo.png" },
    { id: 4, image: "./images/portfolio-smm.png", title: "Self Mad Man", description: "Landing page for a caricature mobile game.", link: "https://selfmadman.fr/", logo: "./images/logos/smm-logo.png" },
    { id: 5, image: "./images/portfolio-courtialet.png", title: "Le Courtialet", description: "Website for a naturist campsite with booking section.", link: "https://lecourtialet.fr/", logo: "./images/logos/courtialet-logo.png" },
    { id: 6, image: "./images/portfolio-nono.png", title: "Portfolio Nolann Gerbault", description: "Clash Royale-themed portfolio showcasing main game screens.", link: "https://nolanncorp.com/", logo: "./images/logos/nono-logo.png" },
    { id: 7, image: "./images/portfolio-idem.png", title: "Idem Automatisme", description: "Website for an industrial engineering company highlighting services.", link: "https://www.idem-automatisme.fr/", logo: "./images/logos/idem-logo.png" },
    { id: 8, image: "./images/portfolio-tran.png", title: "Maître Tran", description: "Website for a French lawyer showcasing her career.", link: "https://www.trantt-avocat.fr/", logo: "./images/logos/tran-logo.png" },
    { id: 9, image: "./images/portfolio-naturismera.png", title: "Naturisme en Rhône-Alpes", description: "Website for the regional naturism federation in Rhône-Alpes.", link: "https://www.naturismerhonealpes.fr/", logo: "./images/logos/naturismera-logo.png" },
    { id: 10, image: "./images/portfolio-antyl.png", title: "Antyl", description: "Website for an urbanism company focused on AI (hackathon project).", link: "https://antyl.webflow.io/", logo: "./images/logos/antyl-logo.png" },
    { id: 14, image: "./images/portfolio-logitech-2.png", title: "Logitech - ForestNation", description: "Landing page for the Logitech and Forest Nation partnership.", link: "", logo: "./images/logos/logo-logitech.png" }
];

export default function MiniPortfolio() {



    return (
        <div id="portfolio" className="mini-portfolio-section">
            <div className="mini-portfolio-title-box">
                <h2 className="mini-portfolio-title">
                    Let's Have a Look at my <span className="mini-portfolio-highlight">Portfolio</span>
                </h2>
                <p className="mini-portfolio-subtitle">Trusted by leading brands and businesses. From startups to global companies, I help craft digital experiences that stand out.</p>

            </div>

            {/* Carrousel Infini avec duplication des logos */}
            <div className="client-carousel-wrapper">
                <div className="client-carousel">
                    {[...Array(15)].flatMap(() => clientLogos).map((logo, index) => (
                        <img key={index} src={logo} alt={`Client ${index + 1}`} className="carousel-logo" />
                    ))}
                </div>
            </div>

            {/* Grille des projets */}
            <div className="project-grid">
                {projects.map((project) => (
                    <div key={project.id} className="project-card">
                        <img src={project.image} alt={project.title} className="project-image" />
                        <div className="project-overlay">
                            {/* <img src={project.logo} alt={`${project.title} Logo`} className="project-logo" /> */}
                            <h3 className="project-title">{project.title}</h3>
                            <p className="project-description">{project.description}</p>
                            {project.link && (
                                <a href={project.link} className="project-button" target="_blank" rel="noopener noreferrer">
                                    Visit Website <i class="bi bi-arrow-right"></i>
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <a href="/contact" className="mini-portfolio-cta">Hire Me<i class="bi bi-arrow-up-right"></i></a>


            {/* Témoignages */}
            <Testimonials />
        </div>
    );
}
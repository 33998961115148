import "./Footer.css";
import "./Root.css";

export default function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-top">
        <ul className="footer-top-left">
          <li className="footer-top-left-link">
            <a href="/home">Home</a>
          </li>
          {/* <li className="footer-top-left-link">
            <a href="/about">About</a>
          </li>
          <li className="footer-top-left-link">
            <a href="/services">Services</a>
          </li>
          <li className="footer-top-left-link">
            <a href="/portfolio">Portfolio</a>
          </li>
          <li className="footer-top-left-link">
            <a href="/blog">Blog</a>
          </li> */}
          <li className="footer-top-left-link">
            <a href="/contact">Contact</a>
          </li>
        </ul>

        <ul className="footer-top-right">
          <li className="footer-top-right-link">
            <a href="https://x.com/paulune_moon" target="_blank">
              <i class="bi bi-twitter"></i>
            </a>
          </li>
          <li className="footer-top-right-link">
            <a href="https://www.linkedin.com/in/pauline-mila-alonso/" target="_blank">
              <i class="bi bi-linkedin"></i>
            </a>
          </li>
          <li className="footer-top-right-link">
            <a href="https://medium.com/@pauline.milaalonso" target="_blank">
              <i class="bi bi-medium"></i>
            </a>
          </li>
          <li className="footer-top-right-link">
            <a href="https://github.com/Milhaal" target="_blank">
              <i class="bi bi-github"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p className="footer-bottom-left">© 2024 All rights reserved</p>

        <div className="footer-bottom-right">
          {/* <a href="" className="footer-bottom-right-link">
            Terms of Service
          </a>
          <a href="" className="footer-bottom-right-link">
            Privacy Policy
          </a> */}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';
import './Root.css';


function Navbar({ }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const navigate = useNavigate();
  
    const togglePopup = (e) => {
      e.preventDefault(); // Prevent default anchor behavior
        navigate('/login'); // Navigate to the login page
    };
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    useEffect(() => {
      const navbarPadding = document.querySelector('.navbar__padding');
      if (isMenuOpen) {
        navbarPadding.style.backgroundColor = 'var(--dark-grey)';
      } else {
        navbarPadding.style.backgroundColor = '';
      }
    }, [isMenuOpen]);
  
    return (
      <nav className={`navbar__padding ${isSticky ? 'sticky-navbar' : ''}`}>
        <div className="navbar__pc">
          
          
            <ul className="nav__links nav__links-left">
              <li>
                <a href="/home" className="nav__links-btn">
                  Home
                </a>
              </li>
              {/* <li   >
                <a href="/about" className="nav__links-btn">
                  About
                </a>
              </li> */}
              {/* <li   >
                <a href="/services" className="nav__links-btn">
                  Services
                </a>
              </li> */}
              {/* <li   >
                <a href="/portfolio" className="nav__links-btn">
                  Portfolio
                </a>
              </li> */}
            </ul>
            <a href="/home" className="nav__logo">
            <img src="./images/main-logo.png" alt="thirdspace logo" />
          </a>
  
          <ul className="nav__links nav__links-right">
              {/* <li>
                <a href="/blog" className="nav__links-btn">
                  Blog
                </a>
              </li> */}
              <li>
                <a href="/contact" className="nav__links-btn">
                  Contact
                </a>
              </li>
              
              {/* <li>
              <a href="" className="nav__links-lang">
                <i class="bi bi-globe"></i>
                <i class="bi bi-chevron-down"></i>
              </a>
              </li> */}
            </ul>
          
        </div>
  
        <div className="navbar__mobile">
          <div className="navbar__mobile-head">
            <a href=" /home" className="nav__logo">
              <img src="./images/main-logo.png" alt="thirdspace logo" />
            </a>
            <img
              className="navbar__mobile-menuIcon"
              id="menuIcon"
              src={
                isMenuOpen
                  ? "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2Fnavbar-close.png?alt=media&token=3ececfa1-9c7d-450d-8443-53acccc54ffe"
                  : "https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2Fnavbar-burger.png?alt=media&token=bf53017c-e2e0-4108-be6f-7a657e38f786"
              }
              alt=""
              onClick={toggleMenu}
            />
          </div>
          <div
            className={`navbar__mobile-content ${isMenuOpen ? "animate__fadeInLeft" : ""}`}
            style={{ display: isMenuOpen ? "block" : "none", left: isMenuOpen ? '-11%' : '-150%' }}
          >
            <ul className="nav__links">
              <li>
                <a href="/home" className="nav__links-btn">
                  Home
                </a>
              </li>
              {/* <li   >
                <a href="/about" className="nav__links-btn">
                  About
                </a>
              </li> */}
              {/* <li   >
                <a href="/services" className="nav__links-btn">
                  Services
                </a>
              </li> */}
              {/* <li>
                <a href="/portfolio" className="nav__links-btn">
                  Portfolio
                </a>
              </li> */}
              {/* <li>
                <a href="/blog" className="nav__links-btn">
                  Blog
                </a>
              </li> */}
              <li>
                <a href="/contact" className="nav__links-btn">
                  Contact
                </a>
              </li>
              
            </ul>
          </div>
        </div>
  
      </nav>
    );
  }
  
  export default Navbar;
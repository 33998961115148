import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

export default function Blog() {
    return (
        <>
            <Navbar />
            <Footer />
        </>
    )
}